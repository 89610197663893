import React from "react";
import { slide as Menu } from "react-burger-menu";

export default props => {
  return (
    // Pass on our props
    <Menu {...props}>
      <a className="menu-item" href="/">
        Home
      </a>

      <a className="menu-item" href="/FAQ">
        FAQ
      </a>

      <a className="menu-item" href="/News">
        NEWS
      </a>

      <a className="menu-item" href="/wallet">
        GET WALLET
      </a>

      <a className="menu-item" href="/transactions">
        Transactions
      </a>

    </Menu>
  );
};
