import '../index.css';
import React from 'react';
import {NavLink, useParams, useLocation} from 'react-router-dom';
import {useState, useEffect} from "react";
import { useMatomo } from '@datapunt/matomo-tracker-react';
import {Link} from 'react-router-dom';

function getStatusLabel(status) {
    if (status === 0) {
        return "Received";
    } else if (status === 1) {
        return "On Node";
    } else if (status === 2) {
        return "Confirmed";
    } else {
        return status;
    }
}

function getTypeLabel(type) {
    if (type === 0) {
        return "Payout";
    } else if (type === 1) {
        return "Donation";
    } else if (type === 2 || type === 3 || type === 4) {
        return "Bonus Payment";
    } else {
        return type;
    }
}

function Transaction() {
    const location = useLocation();
    let [transaction, setTransaction] = useState(null);
    if (location.state) transaction = location.state.transaction;

    let params = useParams();
    if (!transaction) {
        transaction = {};
        let key = params.id;
        //fetch data
        fetch('/transaction', {method: 'POST', body: JSON.stringify({id: key})})
          .then(response => response.json())
          .then(json => {
              setTransaction(json);
          });
    }
    console.log(transaction)
    const { trackPageView, trackEvent } = useMatomo();
    useEffect(() => {
        trackPageView()
      }, [])


  return(
    <div className="heroFAQ container">
    <div className="con" style={{textAlign: "initial"}}>
        <h1>Transaction</h1>
        <div className="container">
    <ul className="responsive-table">
    <div className="sectionTable">
    <li className="table-header wide30">
      <div className="col">Address</div>
    </li>
    <li className="table-row wide70">
        <div style={{overflow: "hidden", textOverflow: "ellipsis"}} title={transaction.address} className="col" >{transaction.address}</div>
    </li>
    </div>
    <div className="sectionTable">
    <li className="table-header wide30">
      <div className="col">Username</div>
    </li>
    <li className="table-row wide70">
        <div className="col" >{transaction.username ? transaction.username: '-'}</div>
    </li>
    </div>
    <div className="sectionTable">
    <li className="table-header wide30">
      <div className="col">Value</div>
    </li>
    <li className="table-row wide70">
        <div className="col" >{parseFloat(transaction.value).toFixed(3)}</div>
    </li>
    </div>
    <div className="sectionTable">
    <li className="table-header wide30">
      <div className="col">Status</div>
    </li>
    <li className="table-row wide70">
        <div className="col" >{getStatusLabel(transaction.status)}</div>
    </li>
    </div>
    <div className="sectionTable">
    <li className="table-header wide30">
      <div className="col">Creation date</div>
    </li>
    <li className="table-row wide70">
        <div className="col" >{new Date(transaction.creationdate).toLocaleString()}</div>
    </li>
    </div>
    <div className="sectionTable">
    <li className="table-header wide30">
      <div className="col">Type</div>
    </li>
    <li className="table-row wide70">
        <div className="col" >{getTypeLabel(transaction.type)}</div>
    </li>
    </div>
    <div className="sectionTable">
    <li className="table-header wide30">
      <div className="col">Node date</div>
    </li>
    <li className="table-row wide70">
        <div className="col" >{transaction.nodedate ? new Date(transaction.nodedate).toLocaleString() : "-"}</div>
    </li>
    </div>
    <div className="sectionTable">
    <li className="table-header wide30">
      <div className="col">Confirmed date</div>
    </li>
    <li className="table-row wide70">
        <div className="col" >{transaction.confirmeddate ? new Date(transaction.confirmeddate).toLocaleString() : "-"}</div>
    </li>
    </div>
    <div className="sectionTable">
    <li className="table-header wide30">
      <div className="col">Node transaction ID</div>
    </li>
    <li className="table-row wide70">
        <div style={{overflow: "hidden", textOverflow: "ellipsis"}} className="col" title={transaction.nodetransactionid}>{transaction.nodetransactionid ? transaction.nodetransactionid : "-"}</div>
    </li>
    </div>
    <div className="sectionTable">
    <li className="table-header wide30">
      <div className="col">Confirmed height</div>
    </li>
    <li className="table-row wide70">
        <div className="col" >{transaction.confirmedheight}</div>
    </li>
    </div>

  </ul>
</div>
    </div>
    </div>
  );
}

export default Transaction;
