import '../index.css';
import {useState, useEffect} from 'react';
import Question from '../components/Question';
import { useMatomo } from '@datapunt/matomo-tracker-react';

function FAQ() {
    const { trackPageView, trackEvent } = useMatomo();
    useEffect(() => {
        trackPageView()
      }, [])

    const Questionlist = [
        {
            question: "What is Marmots.oi?",
            answer: 'Marmots.io is an online browser game in which you compete with other players for the opportunity to earn cryptocurrency. We want to develop a relaxing and entertaining game while supporting the adoption of Chia.'
        },
        {
            question: "Why should I play Marmots.io?",
            answer: 'Haven’t you tried already? It’s Fun! You can do it from everywhere and on the most basic devices. All while earning $MIO coin.'
        },
        {
            question: "What is $MIO coin?",
            answer: '$MIO is the cryptocurrency that can be earned and spend while playing Marmots.io. There are only 21 million coins in existence. They will be distributed in-game over the span of 732 days. The distribution includes so-called "halvings", which means that more coins will spawn in the beginning than in the end. It therefore is more lucrative to play in the beginning than in the end ;). <br>More details about the distribution pattern can be found in the <a style="text-decoration: underline;" href="./technical">technical details</a>.<br>$MIO is based on Chia Asset Tokens.'
        },
        {
            question: "Do I need a Crypto Wallet to play?",
            answer: 'You can play without a crypto wallet, but if you want to receive your collected $MIO coins, you will need a wallet for it. Don’t worry, even if you have never made a crypto wallet, it’s quite easy to do. Because $MIO runs on the Chia blockchain, any Chia wallet will work. You can download the official software from the Chia website: <a target="_blank" style="text-decoration: underline;" href="https://www.chia.net/download/">https://www.chia.net/download/</a>. <br><br><b>Don’t share your 24 words, ever!</b>'
        },
        {
            question: "How do I get the $MIO coins from in-game into my wallet?",
            answer: 'To trigger a payout you need to go straight for three seconds and then press “p” or tap the payout button. (This is to make sure that players aren’t surrounded by an enemy and can get away without a fight as well as that no accidental payouts are triggered.) On the appearing screen, enter your wallet address “xch…”. After an anti-bot Captcha, your coins will be put on the transaction list. <br>To show $MIO coins in your chia wallet you need to add their id first. To do so press [+ADD TOKEN], [+Custom] and enter "MIO" as well as the asset id "1dadf5b097d2e1257d375759aaa15298ed3e0f70b6de275d9c70dc2f7eca14a8".'
        },
        {
            question: "Can I buy or sell $MIO coins?",
            answer: 'Yes $MIO can be traded like any other Cryptocurrency. Other players have already collected $MIO and are trading it live, you can do so, too. The truly amazing p2p exchange capability called “Offers” is perfect for this. Check it out at the Chia website: <a target="_blank" style="text-decoration: underline;" href="https://www.chia.net/offers/.">Offers - Chia Network</a>.'
        },
        {
            question: "What can I do with $MIO coins?",
            answer: 'Those who play will earn coins and soon be capable of buying special features as well as limited skins and assets for the game. Of course you can also trade or just keep them. We plan to build an in-game store to buy and trade those game assets.'
        },
        {
            question: "Where can I connect to the Community?",
            answer: 'The best way to connect with us is by joining our <a target="_blank" style="text-decoration: underline;" href="https://discord.gg/5UcQhGyrzQ"> Discord </a> and following us on <a target="_blank" style="text-decoration: underline;" href="https://twitter.com/Marmots_io"> Twitter</a>. There you’ll be able to post your thoughts and questions as well as stay up to date and have fun.'
        },
        {
            question: "Is $MIO bad for the environment?",
            answer: 'Such a question is generally tricky, but since $MIO is running on the Chia blockchain which is much greener than its counterparts, a similar game on other chains would likely be much more polluting. Nevertheless, everything consumes energy.'
        },
        {
            question: "Are you using my computer to mine crypto?",
            answer: 'No, not at all! The transactions of the already minted $MIO coins are validated completely on the Chia Blockchain. Therefore, your computer is only calculating the game that then defines how many coins are transacted to you.'
        },
        {
            question: "What is the $MIO strategic reserve?",
            answer: 'We will hold less than 5% of $MIO coins to help stabilize and grow the community as well as have an incentive for building the best .io game out there.'
        },
        {
            question: "Will you collaborate with NFT artists in the future?",
            answer: 'Since we are here to grow the community, we are open to broad collaborations and therefore also in the NFT realm.'
        },
        {
            question: "Where can I learn more about the technical details of $MIO?",
            answer: 'You can take a look at the nifty details <a style="text-decoration: underline;" href="./technical">here</a>.'
        },
        {
            question: "What is the difference between a coin and a token?",
            answer: 'Both terms are used interchangeably to refer to the same thing, cryptocurrency. To cut things short, coins are issued by the blockchain while tokens run on it. Therefore, the right terminology would be “$MIO-token”, however, “coins” is more commonly used and we therefore adapted it.'
        },
        {
            question: "Why is it named $MIO?",
            answer: '<p style="font-weight: 700; display: inline;">M</p>armots.<p style="font-weight: 700; display: inline;">io</p>'
        },
        {
            question: "How to pronounce $MIO?",
            answer: 'Don’t even try to do it right, better get a CAT that does it for you ;)'
        },
        {
            question: "Where does .io come from?",
            answer: 'Fun fact: .io is an domain ending like .us and .de which are ccTLDs (Country code top-level domains) just for the British Indian Ocean Territory. '
        },
        {
            question: "What happens after 732 days?",
            answer: 'After two years, all coins spent on game assets, skins and features will be redistributed to the players in-game.'
        }
    ];


  return(
    <div className="heroFAQ container">
    <div className="con">
    <h1>FAQ</h1>
    {Questionlist.map((question)=>{
      return(
        <Question question={question.question} answer={question.answer}/>
      )
    })}

    <p style={{marginTop: '20px'}}> Each individual is responsible for identifying and paying taxes and charges as required under applicable law. </p>

    </div>
    </div>
  );
}

export default FAQ;
