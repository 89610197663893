import CycleOne from '../assets/cycleone.png';
import CycleTwo from '../assets/cycletwo.png';

import {useState} from 'react';



function Cycle() {
  const [isActive, setActive] = useState(false);
  const ToggleCycle = () => {
    setActive(!isActive);
  };
return(
  <div className="CycleContainer">
    <div className="CyclePic">
      <div className={`picWrapper ${isActive && 'disable'}`}>
        <img src={CycleOne} />
      </div>

      <div className={`picWrapper ${!isActive && 'disable'}`}>
        <img src={CycleTwo} />
      </div>
        <button className="custom-btn btn-5" onClick={ToggleCycle}><span>What happens after two years?</span></button>
    </div>
    <div className="CycleDis">
      <h1>$MIO LIFE CYCLE</h1>
      <p>At launch the total amount of 21 Million coins will be split into two parts. &#62;95% of the coins will only be accessible to the players through in-game earnings. Due to the native halvings, the earnable coin amount is higher at the beginning and decreases as time progresses. The remaining &#60;5% of coins are going to us developers in order to help stabilise and grow the community as well as have an incentive to build the best .io game out there.<br/>
      After 732 Days, all Coins that are spent on shop items will be directly and fully redistributed in-game for players to earn once again.<br/>
      Further details can be found <a style={{textDecoration: "underline"}} href="./technical">here</a>.
      <br/>
      </p>

    </div>
  </div>
)
}

export default Cycle;
