import News from '../components/news';
import '../index.css';
import hero from '../assets/hero.png';
import coin from '../assets/showcase.png';
import Cycle from '../components/Cycle';
import CycleDy from '../components/CycleDy';
import Three from '../components/Three';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import {useEffect} from 'react';

function Home() {

  function getWidthDimensions() {
      const { innerWidth: width, innerHeight: height } = window;
      console.log(width);
  return {
    width
    };
}

    const { trackPageView, trackEvent } = useMatomo();
    useEffect(() => {
        trackPageView()
      }, [])

  return(
    <div>
    {/* Hero */}
    <div className="hero">
      <div className="heroDis">
           <h1>
               MARMOTS.IO
           </h1>
           <h2>
               First IO Game coming to Chia
           </h2>
           <button className="play">
           <a target="_blank" href="https://marmots.io">PLAY NOW</a>
           </button>
       </div>
       <div className="heroImg">
           <img className="heroImgg" src={hero} alt="Marmot"/>
       </div>
   </div>
   {/* Hero */}
   {/* DIS */}
   <div className="dis">
   <h2>The Game:</h2>
   Marmots.io is an online browser game in which you compete with other players for the opportunity to collect the $MIO cryptocurrency. Therefore, Marmots.io allows you to keep a sense of progress and value that you can control and compare, unlike other .io games which forget your progress after you finished.
   We are developing a relaxing and entertaining game and want to support the adoption of Chia.
   </div>
   {/* DIS */}
   <News width={getWidthDimensions} lists={5}/>
   <div className="showCase">
       <div className="imgContainer"><img className="showCaseImg" src={coin} alt="logo"/></div>
       <div className="showCaseText">
           <h1>THE CURRENCY</h1>
           <p>$MIO is the cryptocurrency that can be earned and spent while playing Marmots.io. There is a limited amount of $MIO in existence that will be distributed in-game over the span of 732 days. It is running on the Chia blockchain as a "Chia Asset
               Token" (CAT). <br/>
               After two years, all coins spent on game assets, skins and features will be redistributed to the players in-game.<br/>
               $MIO can be traded or kept in your wallet like other cryptocurrencies.
               </p>
       </div>
   </div>
   {/* Cycles */}
   <Cycle/>
   <CycleDy/>
   {/* Cycles */}




    </div>
  );
}

export default Home;
