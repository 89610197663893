import Why from './Why';
import Stars from '../assets/stars.svg';
import Cart from '../assets/cart.svg';
import Incr from '../assets/incr.png';
function Three() {
  const Whyies = [
    {
      h2: "Economic principle of scarcity through limited number of $MIO coins",
      img: Stars,
      backh2: "The limited amount of $MIO leads to an economic scarcity over time. Combined with growing demand for the coin, people will be hesitant to sell.",
      backColor: "lightgreen"
    },
    {
      h2: "Increasing asset and Skin trading with $mio",
      img: Cart,
      backh2: "As coins will be used to get limited Skins, assets as well as special features, players will be motivated to trade and buy $MIO coins on the open market.",
      backColor: "white"
    },
    {
      h2: "Growing global crypto markets",
      img: Incr,
      backh2: "As crypto and blockchain venture into new and uncharted territories, their adoption is going to increase.",
      backColor: "lightgray"
    }];
return(
  <div className="ThreeContainer">
      <h1>
      Why Could the MIO COIN Gain Value?
      </h1>
      <div className="ThreeBCon">
      {Whyies.map(why => {return(<Why img={why.img} h2={why.h2} backh2={why.backh2} col={why.backColor}/>)})}
      </div>
  </div>
)
}

export default Three;
