import {useState} from "react";
import styled from "styled-components";
import {Link} from 'react-router-dom';
import testrun from "../assets/news/testrun.png";
import showcase from "../assets/showcase.png";
import walletModal from "../assets/walletModal.png";
import nftShowcase from "../assets/nftShowcase.png";
import Card from './Card';
function NewsP(props){
  const [cards, setCards] = useState([
      {
      title: "News and updates",
      id: 0,
      text: "From now on you will always be up to date!<br/> We created this news channel for continuous status updates about the pre- & post-release game development, current projects & ideas as well as upcoming events.<br> As upcoming our aim is to let you participate in the constant development of the game. We hope, that you will use your chance to react on new posts with further ideas and suggestions in our discord channel. We are looking forward to a great cooperation to grow and enhance marmots.io as a new way of bringing crypto currencies in every gamers life!",
      img: "https://cdn.pixabay.com/photo/2021/08/19/12/37/hill-6557956_960_720.jpg",
      date: "12 April 2022"
      },
      {
      title: "Huge Testrun",
      id: 1,
      text: "Yesterday was our so far biggest test run for the game.<br/>Dozens of our friends were there to test the game dynamics and to find bugs! Everything went very well and we got quite some helpful and interesting ideas as well as advices for future implementations.<br/>Thank you all for the fun and entertaining time!<br/>In the following days we will work on implementing the lessons learned. <br/>Thus the release is getting closer and closer. So Stay tuned!",
      img: testrun,
      date: "20 May 2022"
      },
      {
          title: "Minting $MIO & More",
          id: 2,
          text: `We have finally created the real $MIO which will be put into use on the day of launch and will replace $MIOt which was a trusty test companion for us with far over 1000 transactions. If you want to get your hands on a $MIOt feel free to shoot us a message. However, it is not intended to have any purpose, its sole reason for existence after launch day will be for remembrance of the hard work we put in. <br/>
                After a lot of thinking we came up with an elaborate layout for the safe handling of $MIO that should keep it save from thieves. However, due to security reasons we will not disclose them publicly. Nevertheless, if you are someone in need for a similar system, we for sure can give you some ideas.<br/>
                <br/>
                The updated website now includes more detailed and graphic information about the $MIO coin lifecycle, its dynamics as well as reasons why it might actually gain in value.<br/>
                Did you spot the hidden Chia logo?<br/>
                <br/>
                On the game side of things we have acted on feedback that came up during the past test; the most noticeable addition being a leaderboard for everybody to see who scored highest.`,
          img: showcase,
          date: "20 June 2022"
      },
      {
          title: "CAT1 Vulnerability",
          id: 3,
          text: `As many of you know, the Chia team has discovered a vulnerability within the current CAT1 standard. <br/>
                For us, this means that we have to issue a new token which follows the CAT2 standard. This CAT2 standard will be released at approximately July 26, 17 UTC (block height 2,311,760). In the days after the release, we will airdrop to you the amount of our new token with exactly the amount of $MIO tokens that you have in your wallet at that point. The old token will not be used anymore and can be considered worthless after that. <br/>
                <br/>
                Chia recommends you to do the following things until then:<br/>
                - Cancel any open CAT offers on-chain in your wallet<br/>
                - Do not accept any CAT offers in your 1.4 or lower wallet<br/>
                - Make note of your current CAT1 balances<br/>
                - Upgrade to the latest Chia wallet app (1.5.0 or higher) when it becomes available<br/>
                <br/>
                Visit this Chia blog post to learn more. It has a lot of FAQs that should clear everything up. <a src="https://www.chia.net/2022/07/25/upgrading-the-cat-standard.en.html" target="_blank">https://www.chia.net/2022/07/25/upgrading-the-cat-standard.en.html</a><br/>
                <br/>
                IMPORTANT: Do not trade any CAT1 tokens, including $MIO, after the time of the new CAT2 release. Otherwise we can not ensure that you will have your tokens replaced with our new, secure tokens.<br/>
                <br/>
                We will halt all transactions from now on forward until we issued the new token and replaced your old $MIO with the new $MIO. You can continue playing as normal and we will safe your earned coins. Once everything is sorted out, we will finish all pending transactions with the new, secure $MIO token.<br/>
                <br/>
                Let us know if you have any questions or require assistance.<br/>`,
          img: showcase,
          date: "26 July 2022"
      },
      {
        title: "In-Game NFTs",
        id: 4,
        text: `The new version of the game allows you to verify your ownership of a Marmots.io NFT and shows your ownership to other players in the game.

Depending on the price of the NFT, a different coloured crown will appear next to your name on your Marmot and in the leaderboard. 
The crowns are a test to see if the system works. In the future there will be a lot of different NFTs that work as skins or alter the game in other ways. 
The existing NFT's impact might also change after everything is proven to work.
To verify your NFT status, click on the Wallet Connect button in the bottom left corner and connect your Chia wallet. Then paste your NFT id and click "Prove ownership". The NFT's information will be loaded from the server and you will be asked to sign a message with the NFT's owner address in your Chia wallet. The signature will then be verified by the server and your status can be seen by others. The verification is valid for 30 minutes. After that you will have to sign a message again.
I hope you will enjoy the update and please let me know if you experience any problems with the verification system`,
img: nftShowcase,        
img1: walletModal,
        date: "19 August 2024"
    }
  ]);
  return(
    <div className="blackDiv blackDiv2">
    <Link to={'/news'}><h1>News</h1></Link>
    <div className="newsPageGrid">

    {cards.sort((a, b) => {
        return b.id - a.id;
    })
    .map(card=>{
      return(
        <div>
        <Card img={card.img} title={card.title} text={card.text}  date={card.date} link={card.id}/>
        </div>
      )})}
  </div>

  </div>
)
}

export default NewsP;
