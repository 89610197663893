import React from 'react';
import {NavLink, useParams} from 'react-router-dom';
import logo from '../assets/coin.jpeg';

function Nav() {
  return(

    <div className="navbar">
        <ul className="leftlog">
            <li>
                <a href="/"><img className="logo" src={logo} alt="logo"/></a>
            </li>
            <li>
                MIO
            </li>
        </ul>
        <ul className="links">
            <li className="active">
                <NavLink to={'/'}> HOME</NavLink>
            </li>

            <li className="active padd40">
                <NavLink to={'/FAQ'}> FAQ</NavLink>
            </li>
            <li className="active padd40">
                <NavLink to={'/news'}> NEWS</NavLink>
            </li>
            <li className="active padd40">
                <a href="/wallet">Get Wallet</a>
            </li>
            <li className="active padd40">
                <a href="/transactions">Transactions</a>
            </li>
        </ul>
        <ul className="links leftlinks">
            <li className="active padd40">
                <a target="_blank" href="https://marmots.io">Play</a>
            </li>
            <li className="active padd40 padd2">
                <a target="_blank" href="https://discord.gg/5UcQhGyrzQ">Discord</a>
            </li>
        </ul>
    </div>

  );
}

export default Nav;
