import '../index.css';
import {useState, useEffect} from 'react';
import img1 from "../assets/wallet/1.png";
import img2 from "../assets/wallet/2.png";
import img3 from "../assets/wallet/3.png";
import img4 from "../assets/wallet/4.png";
import img5 from "../assets/wallet/5.png";
import img6 from "../assets/wallet/6.png";
import img7 from "../assets/wallet/7.png";
import img8 from "../assets/wallet/8.png";
import { useMatomo } from '@datapunt/matomo-tracker-react';

function Wallet(){
    const { trackPageView, trackEvent } = useMatomo();
    useEffect(() => {
        trackPageView()
      }, [])

  return(
    <div className="heroFAQ container">
    <div className="con" style={{textAlign: "initial"}}>
        <h1>How to Get a Chia Wallet</h1>
        <div>
            <p style={{marginTop: "20px", marginBottom: "10px"}}>
                Step 1: Download and install the official Chia software from <a style={{textDecoration: "underline"}} href="https://chia.net/download" target="_blank"> chia.net </a>
            </p>
            <img className="walletImage" src={img1}/>
            <p style={{marginTop: "20px", marginBottom: "10px"}}>
                Step 2: Open the installed software and choose "Wallet Mode"
            </p>
            <img className="walletImage" src={img2}/>
            <p style={{marginTop: "20px", marginBottom: "10px"}}>
                Step 3: Click "Create a new private key"
            </p>
            <img className="walletImage" src={img3}/>
            <p style={{marginTop: "20px", marginBottom: "10px"}}>
                Step 4: Securely write down your 24 words. You should never share them with anyone!
            </p>
            <img className="walletImage" src={img4}/>
            <p style={{marginTop: "20px", marginBottom: "10px"}}>
                Step 5: You now have your Chia wallet and can trade Chia coins. To show your $MIO balance click "Manage Token List".
            </p>
            <img className="walletImage" src={img5}/>
            <p style={{marginTop: "20px", marginBottom: "10px"}}>
                Step 6: Then click "+" in the upper right corner
            </p>
            <img className="walletImage" src={img6}/>
            <p style={{marginTop: "20px", marginBottom: "10px"}}>
                Step 7: Enter the name "$MIO" and the asset id: 1dadf5b097d2e1257d375759aaa15298ed3e0f70b6de275d9c70dc2f7eca14a8
            </p>
            <img className="walletImage" src={img7}/>
            <p style={{marginTop: "20px", marginBottom: "10px"}}>
                Step 8: You can now view your $MIO wallet. To get and copy a wallet address and receive coins, click on "receive".
            </p>
            <img className="walletImage" src={img8}/>
        </div>
    </div>
    </div>
  );
}

export default Wallet;
