import Pages from './pages/Pages';
import {BrowserRouter} from 'react-router-dom';
import Nav from './components/Nav';
import './index.css';
import Footer from './components/Footer';
import SideBar from "./components/sidebar";
import ScrollToTop from "./components/scrollToTop";
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react'

const instance = createInstance({
  urlBase: 'https://matomo.marmots.io/',
  siteId: 1,
  disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
  heartBeat: { // optional, enabled by default
    active: true, // optional, default value: true
    seconds: 10 // optional, default value: `15
  },
})

function App() {
  return (
    <MatomoProvider value={instance}>
        <div className="App">
        <BrowserRouter>
        <ScrollToTop>
        <SideBar pageWrapId={"page-wrap"} outerContainerId={"App"} />
        <div id="page-wrap">
        <div id="wrapedpage">
        <Nav/>
        <Pages/>
        <Footer />
        </div>
        </div>
        </ScrollToTop>
        </BrowserRouter>
        </div>
    </MatomoProvider>
  );
}

export default App;
