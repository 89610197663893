import '../index.css';
import {useState, useEffect} from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react';

function Impressum(){
    const { trackPageView, trackEvent } = useMatomo();
    useEffect(() => {
        trackPageView()
      }, [])
  return(
    <div className="heroFAQ container">
    <div className="con" style={{textAlign: "initial"}}>
        <h1>Impressum</h1>
        <div>
            <p>
                <br/><b>Ferdinand Martini</b> <br/>
                Hindenburgstraße 77<br/>
                91054 Erlangen<br/>
                <br/>
                <br/>
                <b>Telefon:</b> +49 (0)15678 / 560282<br/>
                <b>E-Mail:</b> info@marmots.io<br/>
                <br/>
                <br/>
                {/* <b>Umsatzsteuer-Identifikationsnummer gem. § 27 a Umsatzsteuergesetz:</b> DE 1234567<br/>
                // <br/>
                // <br/>
                // <b>Wirtschaftsidentifikationsnummer gem. § 139 c Abgabenordnung:</b> DE 1234567<br/>
                // <br/>
                 <br/> */}
                <b>Redaktionell Verantwortliche:</b> Ferdinand Martini, Hindenburgstraße 77, 91054 Erlangen<br/>
                <br/>
                <br/>
                Link auf die Plattform der EU-Kommission zur Online-Streitbeilegung: https://ec.europa.eu/consumers/odr <br/>
                <br/>
                <br/>
                Zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle sind wir nicht verpflichtet und grundsätzlich nicht bereit.<br/>
            </p>
        </div>
    </div>
    </div>
  );
}

export default Impressum;
