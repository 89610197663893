import '../index.css';
import {useState, useEffect} from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react';

function PP(){
    const { trackPageView, trackEvent } = useMatomo();
    useEffect(() => {
        trackPageView()
      }, [])
      
  return(
    <div className="heroFAQ container">
    <div className="con" style={{textAlign: "initial"}}>
        <h1>Privacy policy</h1>
        <div>
            <p>
                <b>1 – About marmots.io</b><br/>
                Marmots.io is an online browser game in which you compete with other players for the opportunity to earn the MIO cryptocurrency.<br/>
                <br/>
                <b>2 – Purpose of the processing</b><br/>
                Matomo is used to analyse the behaviour of the website visitors to identify potential pitfalls; not found pages, search engine indexing issues, which contents are the most appreciated… Once the data is processed (number of visitors reaching a not found pages, viewing only one page…), Matomo is generating reports for website owners to take action, for example changing the layout of the pages, publishing some fresh content… etc.<br/>
                Matomo is processing the following personal data:<br/>
                 - Cookies<br/>
                 - IP address<br/>
                 - User ID<br/>
                 - Custom Dimensions<br/>
                 - Location of the user<br/>
                <br/>
                And also:<br/>
                 - Date and time<br/>
                 - Title of the page being viewed<br/>
                 - URL of the page being viewed<br/>
                 - URL of the page that was viewed prior to the current page<br/>
                 - Screen resolution<br/>
                 - Time in local timezone<br/>
                 - Files that were clicked and downloaded<br/>
                 - Link clicks to an outside domain<br/>
                 - Pages generation time<br/>
                 - Country, region, city<br/>
                 - Main Language of the browser<br/>
                 - User Agent of the browser<br/>
                <br/>
                The processing of personal data with Matomo is based on legitimate interests.<br/>
                <br/>
                <b>3 – The legitimate interests</b><br/>
                Processing your personal data such as cookies is helping us identify what is working and what is not on our website. For example, it helps us identify if the way we are communicating is engaging or not and how we can organize the structure of the website better. Our team is benefiting from the processing of your personal data, and they are directly acting on the website. By processing your personal data, you can profit from a website which is getting better and better.<br/>
                Without the data, we would not be able to provide you the service we are currently offering to you. Your data will be used only to improve the user experience on our website and help you find the information you are looking for.<br/>
                <br/>
                <b>4 – Recipient of the personal data</b><br/>
                The personal data received through Matomo are sent to:<br/>
                 - Our company.<br/>
                <br/>
                <b>5 – Details of transfers to third country and safeguards</b><br/>
                Matomo data is hosted in Finland.<br/>
                <br/>
                <b>6 – Retention period or criteria used to determine the retention period</b><br/>
                We are keeping the personal data captured within Matomo for a period of 6 months.<br/>
                <br/>
                <b>7 – The existence of each of the data subject’s rights</b><br/>
                As Matomo is processing personal data on legitimate interests, you can exercise the following rights:<br/>
                 - Right of access: you can ask us at any time to access your personal data.<br/>
                 - Right to erasure: you can ask us at any time to delete all the personal data we are processing about you.<br/>
                 - Right to object: you can object to the tracking of your personal data by using the following opt-out feature:<br/>
                 <br/>
                 <iframe
                 id="opt-out-iframe"
                 style={{border: "0", width: "100%", height: "250px"}}
                 src="https://matomo.marmots.io/index.php?module=CoreAdminHome&action=optOut&language=en&backgroundColor=&fontColor=&fontSize=&fontFamily="
                 ></iframe>
                <br/>
                <b>8 – The right to lodge a complaint with a supervisory authority</b><br/>
                If you think that the way we process your personal data with Matomo analytics is infringing the law, you have the right to lodge a complaint with a supervisory authority.<br/>
                <br/>
                <b>9 – Whether the provision of personal data is part of a statutory or contractual requirement; or obligation and possible consequences of failing to provide the personal data</b><br/>
                If you wish us to not process any personal data with Matomo, you can opt-out from it at any time. There will be no consequences at all regarding the use of our website.<br/>
                <br/>
                <b>10 – The existence of automated decision-making, including profiling and information about how decisions are made, the significance and the consequences</b><br/>
                Marmots.io is not doing any profiling.<br/>
            </p>
        </div>
    </div>
    </div>
  );
}

export default PP;
