import '../index.css';
import {Link} from 'react-router-dom';
import {useState, useEffect} from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react';

function getStatusLabel(status) {
    if (status === 0) {
        return "Received";
    } else if (status === 1) {
        return "On Node";
    } else if (status === 2) {
        return "Confirmed";
    } else {
        return status;
    }
}

function getStatusClass(status) {
    if (status === 0) {
        return "received";
    } else if (status === 1) {
        return "node";
    } else if (status === 2) {
        return "confirmed";
    } else {
        return status;
    }
}

function getTypeLabel(type) {
    if (type === 0) {
        return "Payout";
    } else if (type === 1) {
        return "Donation";
    } else if (type === 2 || type === 3 || type === 4) {
        return "Bonus Payment";
    } else {
        return type;
    }
}

function Transactions({ transactions = [] }) {

    const [currentCursor, setCurrentCursor] = useState(null);
    const [transactionsArray, setTransactions] = useState([]);
    const [reachedEnd, setReachedEnd] = useState(false);

    const { trackPageView, trackEvent } = useMatomo();
    useEffect(() => {
        trackPageView();
        getNextTransactions();
      }, [])

    function getNextTransactions() {
    if (reachedEnd) return;
    fetch('/transactions', { method: 'POST', body: JSON.stringify({cursor: currentCursor})})
        .then(response => response.json())
        .then(json => {
            if (json.cursor == null) {
                setReachedEnd(true)
            }
            setTransactions(transactionsArray.concat(json.rows));
            setCurrentCursor(json.cursor);
        });
}

  return(
      <div className="heroFAQ container">
          <div className="con" style={{textAlign: "initial"}}>
              <h1>Transactions</h1>
              <div className="container">
                  <ul className="responsive-table">
                      <li className="table-header">
                        <div className="col col-1"></div>
                        <div className="col col-2">Username</div>
                        <div className="col col-3">Status</div>
                        <div className="col col-4">Type</div>
                        <div className="col col-5">Address</div>
                      </li>
                      {transactionsArray.map(t => {
                          return(<Link to={'/transaction/'+t.transactionid} state={{transaction: t}}>
                          <li className="table-row">
                            <div className="col col-1"><div className={"status-light " + getStatusClass(t.status)}></div></div>
                            <div className="col col-2" >{t.username ? t.username: '-'}</div>
                            <div className="col col-3" >{getStatusLabel(t.status)}</div>
                            <div className="col col-4" >{getTypeLabel(t.type)}</div>
                            <div style={{overflow: "hidden", textOverflow: "ellipsis"}} title={t.address} className="col col-5" >{t.address}</div>
                          </li>
                          </Link>)
                      })}
                </ul>
            </div>
            <div className="divload">
                <button disabled={reachedEnd ? true : false} onClick={getNextTransactions} className="custom-btn btn-5 loadtrans"><span>LOAD MORE</span></button>
            </div>
        </div>
      </div>
  );
}

export default Transactions;
