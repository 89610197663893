import '../index.css';
import {useState, useEffect} from 'react';
import halvings from '../assets/halvings.png';
import { useMatomo } from '@datapunt/matomo-tracker-react';

function Technical(){
    const { trackPageView, trackEvent } = useMatomo();
    useEffect(() => {
        trackPageView()
      }, [])
  return(
    <div className="heroFAQ container">
    <div className="con" style={{textAlign: "initial"}}>
        <h1>Technical Details</h1>
        <div>
            <p>
                There are two types of metrics in the game: <br/>
                The Score is spawned at a constant rate on all game servers and decides your progress in-game. The experience of playing the game will therefore not change.<br/>
                The value, however, refers to the $MIO coins that are spawned in-game. As mentioned before, the fixed amount of $MIO coins will be distributed over a span of 732 days. That means, that a certain amount of $MIO coins is split between current players every second. This amount is therefore split between game servers, depending on how many players are playing on them. It also means that the amount of $MIO coins that is spawned per player, is highly dependent on the amount of currently online players. The more players are playing, the less $MIO coins will a single player be able to collect.<br/>
                The spawning of $MIO coins per second will also change over time with three halvings. Have a look at the following table to see the exact spawned amount:<br/>
                <br/>
                <div className="techImgCon">
                <img className="techImg" src={halvings}/>
                </div>
                <br/>
                <br/>
                One example of coin distribution:<br/>

                Before the first halving, roughly 0.67 $MIO coins are spawned every second. If only one player is online, they have the chance to collect this whole amount by themselves.<br/>
                If two players are online, they each collect roughly 50% of the spawned coins. Of course they will also compete against each other and if one of them eats the other one, they can collect all their dropped $MIO coins as well.<br/>
                If many more players are online and they are split between two game servers, the 0.67$MIO coins are distributed between the servers depending on the amount of players on each server.<br/>
                <br/>
                The amount of $MIO coins a player collects is usually not a whole number, but a number with lots of decimal places. We can only make transactions with a precision of 0.001 $MIO coins. In order to still distribute all $MIO coins, the excess of every transaction is added up and paid out to the top players of the day (by score), who have triggered payouts. The best player will receive 50% of the excess, the second best 30% and the third best 20%.<br/>

                For example: Ten transactions were made during the last 24 hours and each player has collected 10.45783 $MIO coins. Each of these players will receive 10.457 $MIO coins. 10 * 0.00083 = 0.0083 coins were not transacted. The best of these ten players will therefore receive an additional 0.004 $MIO coins (0.0083 $MIO coins * 50%), ...<br/>
                If a player leaves the game without triggering a payout, they will die as normal and all their $MIO coins will be dropped for other players to collect. If a payout was triggered, but no wallet address was given within 15 minutes, the amount will automatically be returned to freshly spawning coins in-game.<br/>
            </p>
        </div>
    </div>
    </div>
  );
}

export default Technical;
