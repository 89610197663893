import News from '../components/news';
import '../index.css';
import NewsP from '../components/newsP';
import {useState, useEffect} from "react";
import { useMatomo } from '@datapunt/matomo-tracker-react';

function Newspage() {
    const { trackPageView, trackEvent } = useMatomo();
    useEffect(() => {
        trackPageView()
      }, [])
  return(
    <div>
    <NewsP id="newsPageGrid"/>

    </div>
  );
}

export default Newspage;
