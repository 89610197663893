import React from 'react';
import {useState} from 'react';
import '../index.css';

function Question(props){
  const [isActive, setActive] = useState(false);
  const ToggleClass = () => {
    setActive(!isActive);
  };
  return(
    <div className="question">
        <button onClick={ToggleClass} className={`accordion ${isActive ? "active" : ""}`}>
        {props.question}
        </button>
        <div className={`answer panel ${isActive ? "show" : ""}`} dangerouslySetInnerHTML={{__html: props.answer}}>
        </div>
    </div>
  )
}

export default Question;
