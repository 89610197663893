
import Dyn from '../assets/dyn.png';

function CycleDy() {
return(
  <div className="CycleDyContainer">
  <div className="CycleDyDis">
    <h1>$MIO DYNAMICS</h1>
    <p>
    The Coins which you have earned while playing are transferred directly into your wallet where you can do whatever you want with them. You can trade them on the open market for other coins or even $USD, you can give them to friends or keep them to yourself for ever. You could also buy, own and trade limited on-chain game skins/ assets in order to show off. Gone are the .io games where you play and don’t get something in return.
    </p>

  </div>
  <div className="CycleDyPic">
      <div className="picWrapperStatic">
        <img src={Dyn} />
      </div>
    </div>

  </div>
)
}

export default CycleDy;
