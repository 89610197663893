import '../index.css';
import {useState, useEffect} from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react';

function Contact(){
    const { trackPageView, trackEvent } = useMatomo();
    useEffect(() => {
        trackPageView()
      }, [])

  return(
    <div className="heroFAQ container">
    <div className="con" style={{textAlign: "initial"}}>
        <h1>Contact</h1>
        <div>
            <p>
                For questions, suggestions or problems, please send an e-mail to <a style={{textDecoration: "underline"}} href="mailto:info@marmots.io">info@marmots.io</a>.
            </p>
        </div>
    </div>
    </div>
  );
}

export default Contact;
