import {Link} from 'react-router-dom';


function Card(props){
  return(
    <div className="plan">
    <Link to={'/news/'+props.link}>
    <div className="Ncard Ncard--secondary">
          <div className="Ncard__header">
            <img className="NcardImg" src={props.img} />
          </div>
          <div className="Ncard__body">
             <p className="Ncardtitle" >{props.title}</p>
              <p className="para" dangerouslySetInnerHTML={{__html: props.text.split('.')[0] + ' [...]'}}></p>
              <div className="corner"> </div>
              <div className="postDate">{props.date}</div>
          </div>
    </div>
    </Link>
    </div>
  );
}

export default Card;
