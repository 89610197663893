import Home from './home';
import Newspage from './newspage';
import Newscard from './newscard';
import Contact from './contact';
import Technical from './technical';
import Transactions from './Transactions';
import Transaction from './Transaction';
import Impressum from './impressum';
import Wallet from './wallet';
import FAQ from './FAQ';
import PP from './pp';
import {Route, Routes, BrowserRouter} from 'react-router-dom';
import '../index.css';

function Pages() {
  return(

    <Routes>
    <Route path="/" element={<Home/>} />
    <Route path="/news" element={<Newspage/>} />
    <Route path="/FAQ" element={<FAQ/>} />
    <Route path="/news/:id" element={<Newscard/>} />
    <Route path="/privacy-policy" element={<PP/>}/>
    <Route path="/contact" element={<Contact/>}/>
    <Route path="/technical" element={<Technical/>}/>
    <Route path="/impressum" element={<Impressum/>}/>
    <Route path="/wallet" element={<Wallet/>}/>
    <Route path="/transactions" element={<Transactions/>} />
    <Route path="/transaction/:id" element={<Transaction/>} />
    </Routes>

  );
}

export default Pages;
